let yearFrom = 2017;
const currentYear = new Date().getFullYear();
let yearTo = currentYear;
const years = [];

while (yearTo >= yearFrom) {
  years.push(yearTo);
  yearTo += -1;
}

const variables = {
  providerId: 11,
  currentYear: currentYear,
  years: years,
};

export default variables;
