import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
} from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
    align-items: top;
  }
`;

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const SingleMetric = (props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [groupBy, setGroupBy] = useState("athlete");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedLevels, setSelectedLevel] = useState(
    props.location.state.selectedLevels || []
  );

  const [selectedYears, setSelectedYear] = useState(
    props.location.state.selectedYears || [envVars.currentYear]
  );

  let levelParams = "";

  if (selectedLevels.length === 0) {
    levelParams = "";
  } else {
    selectedLevels.forEach((level) => {
      levelParams = levelParams + `&class_category_group_codes[]=${level.attributes.classCategoryGroupCode}`;
    });
  }

  let yearParams = "";

  selectedYears.forEach((year) => {
    yearParams = yearParams + `&years[]=${year}`;
  });

  const recordsUrl = `records`
    + `?metric_type=${props.location.state.metric.metric.toLowerCase()}`
    + `&group_by=${groupBy}`
    + `&class_category_group_type=risk_dashboard_post_competition`
    + `&return_top=100`
    + `${levelParams}`
    + `${yearParams}`;

  const { getAll: getAllRecords, all: records } = useResource(recordsUrl);

  useEffect(
    () => {
      const fetchData = async () => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        await getAllRecords();
        setIsLoading(false);
      };

      fetchData();
    },
    [getAllRecords, selectedLevels, selectedYears, groupBy]
  );

  const classCategoriesUrl =
    "class_category_group_codes"
    + "?class_category_group_type=stats_centre_records";

  const { getAll: getAllClassCategories, all: classCategories } = useResource(classCategoriesUrl);

  useEffect(
    () => {
      getAllClassCategories();
    },
    [getAllClassCategories]
  );

  const removeClass = (index) => {
    const newArr = [...selectedLevels];
    newArr.splice(index, 1);
    setSelectedLevel(newArr);
  };

  const camelize = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });
  };

  return (
    <>
      <Text size="x6" weight="semibold">
        {props.location.state.metric.title}
      </Text>
      <Spacer height="x4" />
      <OutterWrapper>
        {classCategories && (
          <DropdownContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Dropdown
                onChange={(item) => {
                  if (item.title === "All Levels") {
                    setSelectedLevel(item);
                  } else {
                    if (selectedLevels.length === classCategories.length) {
                      setSelectedLevel([item]);
                    } else {
                      setSelectedLevel([...selectedLevels, item]);
                    }
                  }
                }}
                readOnly={true}
                items={classCategories.data}
                itemToString={(item) => "Select a Level"}
                placeholder="Select a Level"
                initialItem={classCategories.data[0]}
                handleFilter={() => true}
                displaySuggestion={(item) => (
                  <HStack align="center">
                    <input
                      type="checkbox"
                      style={{ backgroundColor: theme.colors.primary100 }}
                      checked={selectedLevels
                        .map((lvl) => lvl.attributes.classCategoryGroupCode)
                        .includes(item.attributes.classCategoryGroupCode)}
                      onChange={() => null}
                    />
                    <Text size="x3">
                      {item.attributes.classCategoryGroupCode}
                    </Text>
                  </HStack>
                )}
              />
              <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                {selectedLevels.length === 0 ? (
                  <div
                    style={{
                      marginTop: theme.spacing.x1,
                      marginLeft: theme.spacing.x1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text size="x4">All Levels</Text>
                    <Spacer width="x2" />
                    <svg
                      onClick={() => setSelectedLevel([])}
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                    </svg>
                    <Spacer width="x4" />
                  </div>
                ) : (
                  selectedLevels.map((level, idx) => {
                    return (
                      <div
                        style={{
                          marginTop: theme.spacing.x1,
                          marginLeft: theme.spacing.x1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text size="x4">
                          {level.attributes.classCategoryGroupCode}
                        </Text>
                        <Spacer width="x2" />
                        <svg
                          onClick={() => removeClass(idx)}
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 24 24"
                        >
                          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                        </svg>
                        <Spacer width="x4" />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <Dropdown
              onChange={(item) => setSelectedYear([item])}
              readOnly={true}
              items={envVars.years}
              itemToString={(item) => item}
              initialItem={selectedYears[0]}
              placeholder="Select a Year"
              handleFilter={() => true}
              displaySuggestion={(item) => <Text size="x3">{item}</Text>}
            />
          </DropdownContainer>
        )}
        <FilterContainer>
          <div
            onClick={() => {
              setActiveFilter(0);
              setGroupBy("athlete");
            }}
          >
            <Filter active={activeFilter === 0} text="Rider" />
          </div>
          <div
            onClick={() => {
              setActiveFilter(1);
              setGroupBy("horse");
            }}
          >
            <Filter active={activeFilter === 1} text="Horse" />
          </div>
        </FilterContainer>
      </OutterWrapper>
      <Spacer height="x4" />
      <Spacer height="x8" />
      {isLoading ? (
        <Text size="x5" weight="semibold">
          Loading...
        </Text>
      ) : (
        records && records.data.attributes[camelize(props.location.state.metric.metric)].length > 0 ? (
          <ExpandedStatList
            title={props.location.state.metric.title}
            list={records.data.attributes[camelize(props.location.state.metric.metric)]}
          />
        ) : (
          <Text size="x5" color="grey30" weight="semibold" align="center">
            There are no records matching your search.
          </Text>
        )
      )
      }
    </>
  );
};

export default SingleMetric;
